import styled from "styled-components";

export const TabWrapper = styled.div`
  .react-tabs__tab-list {
    border-bottom: 1px solid #17455d;
    margin: 0 0 10px;
    padding-left: 50px;
    color: white;
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid #17455d;
    background-color: #17455d;
    margin-left: 3px;
    border-bottom: none;
    width: 184px;
    height: 30px;
    text-align: center;
    line-height: 2;
    text-transform: uppercase;
    font-size: 16px;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;

    &:hover {
        transition: 0.5s;
        box-shadow: 0 0 5px #69c8ff;
        outline: none;
    }
  }

  .react-tabs__tab--selected {
    background: #69c8ff;
    border-color: #69c8ff;
    color: black;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  .customShit {
    border-bottom: 1px solid aqua;
  }
`;
