import React from 'react'
import {TransactionTitlesDiv, P, Flex_Start, RowFlexEnd} from './_Breakeven_Styles'

class TransactionTitles extends React.Component{
    render(){
        return(
            <TransactionTitlesDiv>
                <Flex_Start>
                    <P fontSize= "23px;" color= "#717171" padding= "15px 0"> Date </P>
                </Flex_Start>
                <Flex_Start>
                    <P fontSize= "23px;" color= "#717171" padding= "15px 0"> Description </P>
                </Flex_Start>
                <Flex_Start width="80%">
                    <P fontSize= "23px;" color= "#717171" padding= "15px 0"> Amount</P>
                </Flex_Start>
                 <RowFlexEnd >
                    <P fontSize= "23px;" color= "#717171" padding= "15px 0"> %</P>
                 </RowFlexEnd>
            </TransactionTitlesDiv>
        )
    }
}

export default TransactionTitles;