import React from 'react'
import { Card, P, Porter, ColumnCenter, SpaceAround, Score, Image, Hyperlink} from '../../global/_GlobalStyles'
import Arrow from "../../../assets/Arrow.png";

class CreditScore extends React.Component{
    render(){
        let difference="5"
        return(
            <Card width="75%" height="176px" flexDirection="column" justifyContent="space-around">
                <ColumnCenter> 
                    <P>Credit Score</P>
                    <Porter fontSize="35px">750</Porter>
                    <Score>
                        {difference} PTS 
                        {/* When we have actual data being pulled, need a ternary to flip arrow (rotate="rotate(180deg)") if positive*/}
                        <Image src={Arrow} width="1em" alt="Arrow"/>
                    </Score>
                </ColumnCenter>
                <ColumnCenter>
                        <P fontSize="12.5px">Why is my credit score dropping? </P>
                        <Hyperlink fontSize="12px">learn more here</Hyperlink>
                </ColumnCenter>
            </Card>
        )
    }
}

export default CreditScore