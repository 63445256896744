// Dev Note: This will be the modal for the profile page
// Set up to receive data from the backend
// Style

// Library Imports
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

// Assets $ Style Imports
import NavBar from "../navBar/NavBar.js";
import { EndpointContainer } from "../global/_EndpointContainer.js";
import { EndpointDisplay } from "../global/_EndpointDisplay.js";

export default class Profile extends React.Component {
  render() {
    return (
      <EndpointContainer>
        <NavBar />
        <ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
          <EndpointDisplay>
            <h1>Profile</h1>
          </EndpointDisplay>
        </ScrollAnimation>
      </EndpointContainer>
    );
  }
}
