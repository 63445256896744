import React from "react";
import { useFormik } from "formik";
import { SignUpContainer } from "./LandingStyles.js";

class SignupForm extends React.Component {
  render() {
    return (
      <SignUpContainer>
        <h2>STAY IN TOUCH</h2>
        <p>The team is working hard to get LedgerFire ready for you!</p>
        <p>
          Sign up to get an email when it’s ready. We never send spam. Ever.
        </p>
        <div
          id="mc_embed_signup"
          style={{
            background: "#fff",
            font: "14px Helvetica,Arial,sans-serif",
            width: "80%",
            borderRadius: "7px",
            marginRight: "30px",
          }}
        >
          <form
            action="https://ledgerfire.us10.list-manage.com/subscribe/post?u=206bf94933764405e42de58b2&id=0ece3bf829"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  // defaultValue
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">First Name </label>
                <input
                  type="text"
                  // defaultValue
                  name="FNAME"
                  className
                  id="mce-FNAME"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">Last Name </label>
                <input
                  type="text"
                  // defaultValue
                  name="LNAME"
                  className
                  id="mce-LNAME"
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                />
              </div>{" "}
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_206bf94933764405e42de58b2_0ece3bf829"
                  tabIndex={-1}
                  defaultValue
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  defaultValue="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  style={{
                    backgroundColor: "#17455D",
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </SignUpContainer>
    );
  }
}

export default SignupForm;

// const SignupForm = () => {
// 	// Notice that we have to initialize ALL of fields with values. These
// 	// could come from props, but since we don't want to prefill this form,
// 	// we just use an empty string. If you don't do this, React will yell
// 	// at you.
// 	const formik = useFormik({
// 		initialValues: {
// 			name: "",
// 			message: "",
// 			email: ""
// 		},
// 		onSubmit: (values) => {
// 			alert(JSON.stringify(values, null, 2));
// 		}
// 	});
// <Form_Container onSubmit={formik.handleSubmit}>
// 	<Input_Container>
// 		<Header_Labels htmlFor="name">Name</Header_Labels>
// 		<Input_Box
// 			id="name"
// 			name="name"
// 			type="text"
// 			placeholder="Name"
// 			onChange={formik.handleChange}
// 			value={formik.values.name}
// 		/>
// 	</Input_Container>
// 	<Input_Container>
// 		<Header_Labels htmlFor="email">Email Address</Header_Labels>
// 		<Input_Box
// 			id="email"
// 			name="email"
// 			type="email"
// 			placeholder="Email"
// 			onChange={formik.handleChange}
// 			value={formik.values.email}
// 		/>
// 	</Input_Container>
// 	<Input_Container>
// 		<Header_Labels htmlFor="message">Message</Header_Labels>
// 		<Message_Box
// 			id="message"
// 			name="message"
// 			type="text"
// 			placeholder="Message"
// 			onChange={formik.handleChange}
// 			value={formik.values.message}
// 		/>
// 	</Input_Container>
// 	<Form_Button type="submit">Submit</Form_Button>
// </Form_Container>
