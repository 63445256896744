import React from 'react'
import {
    SpaceBetween,
    P,
    Flex_Start,
    RowFlexEnd,
    ColumnContainer,
    NextButton,
    NextButtonContainer
} from "./_Breakeven_Styles";
import { connect } from "react-redux";

class TransactionItems extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <ColumnContainer>
            {this.props.state.breakeven_data.breakeven_data.fixed_expenses.map(
                (transaction, index) => (
                    <SpaceBetween padding="15px 0" key={index} borderBottom="1px solid #9B9B9B">
                        <Flex_Start>
                            <P theme={{ fontSize: "23px", color: "#414042" }}>
                                {" "}
                                04/18/20
                            </P>
                        </Flex_Start>
                        <Flex_Start>
                            <P theme={{ fontSize: "23px", color: "#414042" }}>
                                {" "}
                                {transaction.transaction_name}{" "}
                            </P>
                        </Flex_Start>
                         <Flex_Start width="75%">
                            <P theme={{ fontSize: "23px", color: "#414042" }}>
                                {" "}
                                {transaction.amount}{" "}
                            </P>
                        </Flex_Start>
                        <RowFlexEnd >
                            <P theme={{ fontSize: "23px", color: "#414042" }}>
                                {" "}
                                25%
                            </P>
                        </RowFlexEnd>
                    </SpaceBetween>
                )
            )}
            <NextButtonContainer> 
                <NextButton>Next</NextButton> 
            </NextButtonContainer>
            </ColumnContainer>
        )
    }
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(TransactionItems);
