import styled from "styled-components";

export const Image = styled.img`
  padding-right: 15px;
  height: 2.9em;

  &.logo {
    position: absolute;
    right: 0px;
    top: 10px;
  }
`;
