// Dev Note: This is the marketing page that the users will see before they log in

// Library Imports
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

// Assets & Styles Imports
import "../../styles/global.css";
import logo from "../../assets/logoPrimary.svg";
import { Container, BodyContainer, FilterContainer } from "./LandingStyles";
import VideoSection from "./VideoSection";
import Bars_Image from "../../assets/summaryBackground.svg";
import OnTheWay from "./OnTheWay";
import Features from "./Features";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import MarketNav from "./Landing_Nav";
import SignupForm from "./SignupForm";

export default class Landing extends React.Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
        <BodyContainer>
          <FilterContainer>
            <Container>
              <MarketNav />
              <VideoSection />
              <OnTheWay />
              <Features />
              <AboutUs />
              <SignupForm />
              <Footer />
            </Container>
          </FilterContainer>
        </BodyContainer>
      </ScrollAnimation>
    );
  }
}
