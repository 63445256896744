import React from "react";
import {
	RowFlexEnd,
	Refresh,
	ContainerColumn,
	AllGoodTextColumn,
	AllGoodText,
	RefreshIcon,
	HumanImage,
	UnsortedP
} from "./_Breakeven_Styles";
import refreshIconImage from "../../assets/fa_refresh.png";
import human from "../../assets/standing_Human.png";

class AllGood extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}
	componentDidMount() {}
	toggleLoading() {
		this.setState({ loading: !this.state.loading });
	}
	render() {
		let isLoading = this.state.loading;
		return (
			<ContainerColumn>
				<RowFlexEnd>
					<Refresh
						width={isLoading ? "100px" : "400px"}
						justifyConent={isLoading ? "center" : "space-between"}
						onClick={() => this.toggleLoading()}
					>
						{isLoading ? (
							""
						) : (
							<UnsortedP
								fontSize={isLoading ? "57px" : "24px"}
								// display={isLoading ? "none" : "block"}
								opacity={isLoading ? "0" : "1"}
							>
								CHECK FOR NEW EXPENSES{" "}
							</UnsortedP>
						)}

						<RefreshIcon
							spin={isLoading ? "rotation 2s infinite linear;" : ""}
							src={refreshIconImage}
							alt="refresh"
						/>
					</Refresh>
				</RowFlexEnd>
				<AllGoodTextColumn>
					<AllGoodText>Everything is sorted.</AllGoodText>
					<AllGoodText>Nothing to see here!</AllGoodText>
				</AllGoodTextColumn>
				<RowFlexEnd>
					<HumanImage src={human} alt="person leaving" />
				</RowFlexEnd>
			</ContainerColumn>
		);
	}
}

export default AllGood;
