import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Lottie from "react-lottie";
import analysis from "../../assets/MarketingPage/Lottiefiles/analysis.json";
import moneyBag from "../../assets/MarketingPage/Lottiefiles/moneybag.json";
import bigBrain from "../../assets/MarketingPage/Lottiefiles/bigBrain.json";
import dataGraphs from "../../assets/MarketingPage/Lottiefiles/dataGraphs.json";
import { FeaturesContainer } from "./LandingStyles";

class Features extends React.Component {
  render() {
    // Lottie animation settings
    const analysisAnim = {
      loop: false,
      autoplay: true,
      animationData: analysis,
    };
    const moneyBagAnim = {
      loop: false,
      autoplay: true,
      animationData: moneyBag,
    };
    const bigBrainAnim = {
      loop: false,
      autoplay: true,
      animationData: bigBrain,
    };
    const dataGraphsAnim = {
      loop: false,
      autoplay: true,
      animationData: dataGraphs,
    };
    return (
      <FeaturesContainer>
        <div className="featureBox evenBox topBox">
          <Lottie options={bigBrainAnim} height={150} width={150} />
          <h2>LEDGERFIRE IS YOUR VIRTUAL CFO</h2>
          <p>
            Does your operations manage your finances or for your finances
            manage your business? Ledgerfire is the only financial platform that
            gives you the wheel to your business so you can drive your business
            from the windshield, not the rear-view mirror.
          </p>
        </div>
        <div className="featureBox">
          <Lottie options={analysisAnim} height={150} width={150} />
          <h2>BUSINESS FINANCES NOW ACCURATELY REFLECT YOUR OPERATIONS</h2>
          <p>
            What is your business breakeven? How well can your business finances
            support your growth? Ledgerfire's innovative AI will provide you
            this critical information on your customized dashboard at login.
            When your personal and business income and expenses change,
            Ledgerfire will notify you immediately by email or text so you'll
            never be caught of guard again.
          </p>
        </div>
        <div className="featureBox evenBox smallBox">
          <Lottie options={moneyBagAnim} height={150} width={150} />
          <h2>CALCULATE YOUR PERSONAL NEEDS</h2>
          <p>
            How do you pay yourself? Your business performance is tied to your
            personal needs as an expense.{" "}
          </p>
          <p>
            Ledgerfire's AI will calculate your salary based on your needs and
            accurately plug that salary in as a business expenses because, your
            pay is not optional, it is why you own your own business!
          </p>
        </div>
        <div className="featureBox">
          <Lottie options={dataGraphsAnim} height={150} width={150} />
          <h2>ACCURATELY CALCULATE YOU NEW EXPENSES</h2>
          <p>
            Can you afford to purchase in new building? New equipment? Hire a
            new employee? What about other expenses like marketing or
            advertising?{" "}
          </p>
          <p>
            See the total impact of expansion and growth to ensure you’re one
            step ahead when deciding how to grow your business.
          </p>
        </div>
      </FeaturesContainer>
    );
  }
}

export default Features;
