import React from "react";
import Horn from "../../assets/horn.png";
import EllipseRed from "../../assets/EllipseRed.png";
import {
  Row_Centered,
  MoneyEnvelope,
  EllipseImg,
  RowFlexStart,
  PSize
} from "./_ModalStyles";

class NewExpenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      top3: ["AT&T", "Service Charge", "Wire Fee"]
    };
  }
  render() {
    return (
      <>
        <Row_Centered className="Top3Header">
          <MoneyEnvelope src={Horn} alt="money in envelope" />
          <PSize color="#F07B7B"> Recently New Expenses</PSize>
        </Row_Centered>
        {this.state.top3.map(expense => (
          <RowFlexStart>
            <EllipseImg src={EllipseRed} alt="ellipse" />
            <PSize color="#F07B7B"> {expense}</PSize>
          </RowFlexStart>
        ))}
      </>
    );
  }
}

export default NewExpenses;
