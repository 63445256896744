import styled from "styled-components";

export const NavMenuButton = styled.div`
  position: fixed;
  margin-left: 13px;
  margin-top: 5px;
  display: flex;
  cursor: pointer;
  z-index: 3;

  h2 {
    font-size: 20px;
    margin-top: 18px;
  }

  img {
    margin-top: 15px;
    height: 30px;
    transition-timing-function: ease-in;
    transition: 0.3s;

    &.image--menu-open {
      transform: rotate(45deg);
      transition-timing-function: ease-in;
      padding-top: 6px;
      transition: 0.3s;
      margin-top: 15px;
      height: 30px;
    }
  }
`;
