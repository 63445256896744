// Dev Note: This will be the modal for the breakeven function.
// Set up to receive data from the backend
// Style
// Step 3

// Library Imports
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ScrollAnimation from "react-animate-on-scroll";
import { connect } from "react-redux";

// Assets $ Style Imports
import { EndpointContainer } from "../global/_EndpointContainer.js";
import NavBar from "../navBar/NavBar.js";
import { EndpointDisplay } from "../global/_EndpointDisplay.js";
import { TabWrapper } from "../global/_TabWrapper.js";
import Breakeven_tab from "./Breakeven_tab.js";
import FixedCosts from "./FixedCosts";
import VariableCosts from "./VariableCosts";
import Unsorted from "./Unsorted";
import { RowSpaceBetween, Submit } from "./_Breakeven_Styles";

class BreakEven extends React.Component {
	state = {
		selectedIndex: 0,
		unSorted: false,
	};

	handleSelect = (index) => {
		this.setState({ selectedIndex: index });
	};

	handleButtonClick(tab_index) {
		// Sends user to the selected tab by passing the prop to the 'more' button
		this.setState({ selectedIndex: tab_index });
	}

	toggleSubmitUnsorted(bool) {
		this.setState({ unSorted: bool });
	}

	render() {
		return (
			<EndpointContainer>
				<NavBar />
				<ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
					<EndpointDisplay>
						<RowSpaceBetween>
							<h1>BreakEven</h1>
							{/* {this.state.unSorted ? ( */}
							<Submit
								// zIndex={this.state.unSorted ? "4" : "-4"}
								opacity={this.state.unSorted ? "1" : "0"}
							>
								SUBMIT COMPLETED ITEMS
							</Submit>
							{/* ) : null} */}
						</RowSpaceBetween>
						<TabWrapper>
							<Tabs
								selectedIndex={this.state.selectedIndex}
								onSelect={this.handleSelect}
							>
								<TabList style={{ marginBottom: "0" }}>
									<Tab onClick={() => this.toggleSubmitUnsorted(false)}>
										CURRENT BREAKEVEN
									</Tab>
									<Tab>UNSORTED EXPENSES</Tab>
									<Tab onClick={() => this.toggleSubmitUnsorted(false)}>
										FIXED COSTS
									</Tab>
									<Tab onClick={() => this.toggleSubmitUnsorted(false)}>
										VARIABLE
									</Tab>
								</TabList>
								<TabPanel>
									<Breakeven_tab
										handleButtonClick={(tab) => this.handleButtonClick(tab)}
									/>
								</TabPanel>
								<TabPanel>
									<Unsorted
										toggleSubmitUnsorted={(bool) =>
											this.toggleSubmitUnsorted(bool)
										}
									/>
								</TabPanel>
								<TabPanel>
									<FixedCosts />
								</TabPanel>
								<TabPanel>
									<VariableCosts />
								</TabPanel>
							</Tabs>
						</TabWrapper>
					</EndpointDisplay>
				</ScrollAnimation>
			</EndpointContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(BreakEven);
