// Dev Note: This will be the 'homescreen' where we will display
// the cards with the concise overview of each section

// Library Imports
import React from "react";
import NavBar from "../navBar/NavBar";
import { connect } from "react-redux";
import ScrollAnimation from "react-animate-on-scroll";
// Assets & Style Imports
import {
  SummaryContainer,
  CardContainer,
  BreakevenCard,
  OfficerCard,
  GrowthCard,
  EquipmentCard,
  RealEstateCard,
  EmployeeCard,
  ContentCointainer,
} from "./_SummaryStyles.js";
import SummaryBreakevenChart from "./SummaryBreakevenChart.js";
import { Link } from "react-router-dom";

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalIsOpen: false,
    };
  }

  render() {
    let { breakeven } = this.props.state.breakeven_data.breakeven_data;
    return (
      <SummaryContainer>
        <NavBar />
        <ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
          <ContentCointainer>
            <h1 className="summary-title">SUMMARY</h1>
            <CardContainer>
              <Link to={`/app-main/breakeven`}>
                <BreakevenCard>
                  <h1>Breakeven</h1>
                  <p>Current Breakeven: <br></br> ${breakeven}</p>
                  <SummaryBreakevenChart />
                </BreakevenCard>
              </Link>
              <OfficerCard>
                <h1>Officer</h1>
              </OfficerCard>
              <GrowthCard>
                <h1>Growth</h1>
              </GrowthCard>
              <EmployeeCard>
                <h1>Employee</h1>
              </EmployeeCard>
              <EquipmentCard>
                <h1>Equipment</h1>
              </EquipmentCard>
              <RealEstateCard>
                <h1>RealEstate</h1>
              </RealEstateCard>
            </CardContainer>
          </ContentCointainer>
        </ScrollAnimation>
      </SummaryContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(Summary);
