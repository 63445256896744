// Library Imports
import React from "react";
import { connect } from "react-redux";

// Assets $ Style Imports
import {
	Row_Centered,
	Flex_Start,
	P,
	ColumnContainer,
} from "./_Breakeven_Styles";
import UnsortedTransactions from "./UnsortedTransactions";
import AllGood from "./AllGood";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			unsortedComponent: false,
		};
	}

	async componentDidMount() {
		console.log(this.props.state.update_expenses_needed);
		// await this.props.
		this.props.state.update_expenses_needed === []
			? this.props.toggleSubmitUnsorted(false)
			: this.setState({ unsortedComponent: true });
		this.props.toggleSubmitUnsorted(true);
	}

	update(item) {
		console.log(item);
	}
	render() {
		return (
			<>
				{this.state.unsortedComponent ? (
					<ColumnContainer>
						<Row_Centered
							theme={{
								borderBottom: "1px solid gray",
								alignItems: "center",
								boxShadow: "0 3px 5px -2px grey",
							}}
						>
							<Flex_Start
								theme={{
									justifyContent: "center",
									borderRight: ".2px solid gray",
								}}
							>
								<P fontSize="20px" color="#434343">
									NAME
								</P>
							</Flex_Start>
							<Flex_Start
								theme={{
									justifyContent: "center",
									borderRight: ".2px solid gray",
								}}
							>
								<P fontSize="20px" color="#434343">
									DATE
								</P>
							</Flex_Start>
							<Flex_Start
								theme={{
									justifyContent: "center",
									borderRight: ".2px solid gray",
								}}
							>
								<P fontSize="20px" color="#434343">
									AMOUNT
								</P>
							</Flex_Start>
							<Flex_Start theme={{ justifyContent: "center" }}>
								<P fontSize="20px" color="#434343">
									EXPENSE TYPE
								</P>
							</Flex_Start>
						</Row_Centered>

						<Flex_Start>
							{" "}
							<UnsortedTransactions update={this.update} />{" "}
						</Flex_Start>
					</ColumnContainer>
				) : (
					<ColumnContainer className="padding">
						<AllGood />
					</ColumnContainer>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(Header);
