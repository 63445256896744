import React from "react";
import { RowCenter,  ColumnCenter } from '../../global/_GlobalStyles'
import BarGraph from "./BarGraph";
import OverviewInfo from "./OverviewInfo";

class Overview extends React.Component {
    constructor(props){
        super(props)
    }
    
	render() {
		return (
                <RowCenter>
                    <BarGraph />
                    <OverviewInfo handleButtonClick={this.props.handleButtonClick} />
                </RowCenter>
		);
	}
}

export default Overview;
