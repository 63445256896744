import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { AboutUsContainer } from "./LandingStyles";

class AboutUs extends React.Component {
  render() {
    return (
      <AboutUsContainer>
        <h2>ABOUT US</h2>
        <p>
          We are a team of small business owners that have come together to
          solve a problem.<br></br> At LedgerFire we understand the importance
          of managerial accounting because we’ve dealt with it.
        </p>
        <p>
          For us it’s about providing accessible solutions to small and medium
          sized businesses and nothing else.
          <br></br>
          This platform helps people solve some of the hardest questions small
          business owners deal with on a daily basis.
        </p>
      </AboutUsContainer>
    );
  }
}

export default AboutUs;
