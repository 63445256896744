import styled from "styled-components";
import epImg from "../../assets/endpointBackground.svg";

export const EndpointContainer = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  background-image: url(${epImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    margin-left: 40px;
    padding-top: 20px;
  }
`;
