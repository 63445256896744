import React from "react";
import { ColumnContainer } from "./_Breakeven_Styles";
import Header from "./Header";

class Unsorted extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<ColumnContainer>
				<Header
					toggleSubmitUnsorted={(bool) => this.props.toggleSubmitUnsorted(bool)}
				/>
			</ColumnContainer>
		);
	}
}

export default Unsorted;
