// Dev Note: This is the nav bar.

// Library Imports
import React from "react";

// Assets & Styles Imports
import "../../styles/global.css";
import logo from "../../assets/logoPrimary.svg";
import menu from "../../assets/menuIcon.svg";
import profile from "../../assets/profilePic.png";
import { NavBox } from "./_NavBox.js";
import { NavContainer } from "./_NavContainer.js";
import { NavMenuButton } from "./_NavMenuButton.js";
import { Estimates } from "./_Estimates";
import { Projections } from "./_Projections";
import { Profile } from "./_Profile";
import { Estimates_Div_Section } from "./_Estimates_Div_Section";
import { Image } from "./Image";
import Salary from "../../assets/salary.png";
import Scale from "../../assets/business.png";
import Graph from "../../assets/business-and-finance.png";
import Peeps from "../../assets/avatar.png";
import Support from "../../assets/support.png";
import Home from "../../assets/home.png";
import { Link } from "react-router-dom";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: true,
    };
  }

  openClose = () => {
    if (this.state.menuIsOpen === false) {
      this.setState({
        menuIsOpen: true,
      });
      let menuButton = document.getElementById("menuButton");
      this.rotateOpen(menuButton);
      let menuToggle = document.getElementById("menuBox");
      this.menuOpen(menuToggle);
    } else {
      this.setState({
        menuIsOpen: false,
      });
      let menuButton = document.getElementById("menuButton");
      this.rotateClose(menuButton);
      let menuToggle = document.getElementById("menuBox");
      this.menuClose(menuToggle);
    }
  };

  menuClose(menuToggle) {
    menuToggle.classList.remove("hidden-menu-toggle");
  }

  rotateClose(menuButton) {
    menuButton.classList.remove("image--menu-open");
    menuButton.classList.add("image--menu-closed");
  }

  menuOpen(menuToggle) {
    menuToggle.classList.add("hidden-menu-toggle");
  }

  rotateOpen(menuButton) {
    menuButton.classList.add("image--menu-open");
    menuButton.classList.remove("image--menu-closed");
  }

  render() {
    return (
      <NavContainer>
        <Profile>
          <Link className="profile-link" to={`/app-main/profile`}>
            <img src={profile} alt="Profile picture" />
            <p>Absolute</p>
            <p> Fashion</p>
          </Link>
        </Profile>
        <NavBox className="hidden-menu-toggle" id="menuBox">
          <Link className="GoHome" to={`/app-main`}>
            SUMMARY
          </Link>
          <Estimates>
            <h1>Estimates links</h1>
            <Estimates_Div_Section>
              <Link to={`/app-main/salary`}>
                <Image src={Salary} />
                <h4 className="section-text">SALARY</h4>
              </Link>
            </Estimates_Div_Section>
            <Estimates_Div_Section>
              <Link to={`/app-main/breakeven`}>
                <Image src={Scale} />
                <h4>BREAKEVEN</h4>
              </Link>
            </Estimates_Div_Section>
            <Estimates_Div_Section>
              <Link to={`/app-main/financials`}>
                <Image src={Graph} />
                <h4>FINANCIALS</h4>
              </Link>
            </Estimates_Div_Section>
          </Estimates>
          <Projections>
            <h1>Projections links</h1>
            <Estimates_Div_Section>
              <Link to={`/app-main/employee`}>
                <Image src={Peeps} />
                <h4>EMPLOYEE</h4>
              </Link>
            </Estimates_Div_Section>
            <Estimates_Div_Section>
              <Link to={`/app-main/equipment`}>
                <Image src={Support} />
                <h4>EQUIPMENT</h4>
              </Link>
            </Estimates_Div_Section>
            <Estimates_Div_Section>
              <Link to={`/app-main/realestate`}>
                <Image src={Home} />
                <h4>REAL ESTATE</h4>
              </Link>
            </Estimates_Div_Section>
          </Projections>
        </NavBox>
        <NavMenuButton onClick={this.openClose}>
          <Image
            className="image--menu-open"
            id="menuButton"
            src={menu}
            alt="menu button"
          />
          <h2>{this.state.menuIsOpen === false ? "MENU" : "CLOSE"}</h2>
        </NavMenuButton>
        <Image className="logo" src={logo} alt="LedgerFire Logo" />
      </NavContainer>
    );
  }
}
