import styled from "styled-components";

export const Profile = styled.div`
    position: fixed;
    display: flex;
    flex-directions: row;
    top: 75px;
    left: 10px;
    height: 50px;
    width: 150px;
    z-index: 5;

    img {
        margin-top: 5px;
        margin-right: 5px;
        height: 40px;
    }

    .profile-link {
        position: absolute;
        display: flex;
        width: 99%;
        height: 50px;
        justify-content: flex-start;
        align-items: center;
        transition: .7s ease;
        font-weight: bold;
        font-size: 16px;
        color: #000;
        &:hover {
            // background-color: #16455D;
            cursor: pointer;
        }
      }
`;
