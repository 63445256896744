import React from "react";
import { MarketFooter } from "./LandingStyles";

class Footer extends React.Component {
  render() {
    return (
      <MarketFooter>
        <p>Copyright 2020 ledgerfire and M&M</p>
      </MarketFooter>
    );
  }
}

export default Footer;
