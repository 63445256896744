import React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import { connect } from "react-redux";

import "../styles/global.css";

class Welcome extends React.Component{
    constructor(props){
    super(props)
    
    this.state={
        transition: "fadeIn",
        lastName: ""
    }
  }

  componentDidMount(){
      this.setState({lastName: this.props.state.user_info.username})
      setTimeout(()=> {
          this.setState({transition: "fadeOut"})
      }, 2800) 
    }
    render(){

    if(this.state.transition === "fadeOut"){
        setTimeout(()=> {
          this.props.history.push('/app-main')
      }, 2000) 
    }
        return(
            <div className= {` container--fullscreen-welcome ${this.state.transition}` } >
                <div className="splashBar--container">
                    <div className="splashBar splashBar--light" />
                    <div className="splashBar splashBar--dark" />
                </div>
                <h1 className="WelcomeText">Welcome back {this.state.lastName}</h1>
                <div className="splashBar--container-bottom">
                    <div className="splashBar splashBar--light" />
                    <div className="splashBar splashBar--dark" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(Welcome);
