import styled from "styled-components";

export const InfoModalDiv = styled.div `
    width: 100%;
`

export const Row_Centered = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: ${props => props.padding};
`;

export const RowFlexStart = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  .Top3Items {
    padding: 25px 0;
  }
`;

export const FixedContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Define our button
export const Div = styled.div`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
  /* Color the border and text with theme.main */
  color: ${props => props.theme.main};
  border: 2px solid ${props => props.theme.main};
`;
export const P = styled.p`
  font-size: ${props => props.fontSize};
`;
// Define what main theme will look like
export const PSize = styled.p`
  /* .CustomBlue { */
  font-size: 20px;
  text-transform: uppercase;
  /* } */
  color: ${props => props.color};
`;

export const ColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => {
		return props ? props.width : "100%";
    }};
  margin: ${(props) => {
		return props ? props.margin : "";
    }};
  padding: ${(props) => {
		return props ? props.padding : "";
    }};
`;

export const InfoModalContainer = styled.div`
  width: 357px;
  /* height: 509px; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  padding: 15px;
`;

export const OptionButton = styled.div`
  width: 117px;
  height: 40px;
  color: ${props => props.color};
  background: ${props => props.background};
  border: 1px solid #434343;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
`;

export const MoneyEnvelope = styled.img`
  width: 4em;
`;

export const EllipseImg = styled.img`
  width: 3em;
  padding-right: 15px;
`;
