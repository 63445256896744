import styled from "styled-components";
import "../../assets/web fonts/portersans_block_macroman/stylesheet.css";
import marketingBG from "../../assets/marketing_background1.svg";

export const BodyContainer = styled.div`
  background-image: url(${marketingBG});
`;

export const FilterContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
`;

export const Container = styled.div`
  @media only screen and (min-width: 300px) {
    width: 100%;
    position: relative;
    background-color: #fff;
  }

  @media only screen and (min-width: 1000px) {
    margin: 0 auto;
    width: 1000px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  }
`;

//
// Navbar Styles
//

export const LandingNav = styled.div`
  @media only screen and (min-width: 300px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 60px;
    width: 100%;

    .navLogo {
      width: auto;
      height: 40px;
      margin: 10px;
    }
  }
`;

export const Back_To_Top_Button = styled.div`
  height: 55px;
  width: 55px;
  opacity: 0;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1000;
  border-radius: 80px;
  border: 2px solid #38b6ff;
  background-color: #063852;
  transition: 0.3s ease;
  cursor: default;

  img {
    height: 50px;
    margin-left: 3px;
  }
`;

//
// Video Styles
//

export const VideoContainer = styled.div`
  @media only screen and (min-width: 300px) {
    width: 100%;
    margin: 0 auto;

    .marketingVideo {
      width: 100%;
    }
  }
`;

//
// OnTheWay Styles
//

export const CtaContainer = styled.div`
  @media only screen and (min-width: 300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #063852;

    .CtaText {
      margin: 0 auto;
      width: 80%;
      h2 {
        color: #fff;
        line-height: 56px;
        letter-spacing: 4px;
        text-align: center;
        font-family: "Poiret One", cursive;
        font-size: 42px;
        text-shadow: 6px 2px 2px rgba(106, 200, 255, 0.31);
      }
    }
  }

  @media only screen and (min-width: 900px) {
    .CtaText {
      h2 {
        font-size: 48px;
        line-height: 62px;
      }
    }
  }
`;

//
// Features Styles
//

export const FeaturesContainer = styled.div`
  @media only screen and (min-width: 300px) {
    display: flex;
    margin: 10px auto;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .featureBox {
      margin-bottom: 20px;
      padding-top: 20px;
      width: 100%;

      h2 {
        font-size: 22px;
        text-align: center;
      }

      p {
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
    .evenBox {
      background-color: #ecf8ff;
    }
  }

  @media only screen and (min-width: 650px) {
    .featureBox {
      p {
        width: 80%;
      }
    }
  }

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    justify-content: space-around;
    .featureBox {
      width: 45%;
    }
    .evenBox {
      background-color: #fff;
    }

    .smallBox {
      height: 490.5px;

      h2 {
        margin-top: 28px;
        margin-bottom: 44px;
      }
    }
    .topBox {
      width: 65%;
    }
  }
`;

//
// AboutUs Styles
//

export const AboutUsContainer = styled.div`
  @media only screen and (min-width: 300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #063852;

    h2 {
      margin-bottom: 0px;
      color: #fff;
      line-height: 56px;
      letter-spacing: 4px;
      text-align: center;
      font-family: "Poiret One", cursive;
      font-size: 42px;
      text-shadow: 6px 2px 2px rgba(106, 200, 255, 0.31);
    }

    p {
      color: #fff;
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 650px) {
    p {
      width: 80%;
    }
  }
`;

//
// SignUp Styles
//

export const SignUpContainer = styled.div`
  @media only screen and (min-width: 300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 {
      margin-bottom: 10px;
      color: #063852;
      line-height: 56px;
      letter-spacing: 3px;
      text-align: center;
      font-family: "Poiret One", cursive;
      font-size: 42px;
      text-shadow: 6px 2px 2px rgba(106, 200, 255, 0.31);
    }

    p {
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }
`;

//
// Footer Styles
//

export const MarketFooter = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    margin: 0 auto;
  }
`;
