import React from "react";
import Ledgerfire_video from "../../assets/Ledgerfire_video.mp4";
import { VideoContainer } from "./LandingStyles";

class VideoSection extends React.Component {
  render() {
    return (
      <VideoContainer>
        <video
          className="marketingVideo"
          src={Ledgerfire_video}
          ref="vidRef"
          type="video/mp4"
          loop
          autoPlay
          playsInline
          controls
          muted
        />
      </VideoContainer>
    );
  }
}

export default VideoSection;
