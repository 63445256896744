import styled from "styled-components";

export const NavBox = styled.div`
  position: fixed;
  top: 0px;
  left: -5px;
  z-index: 2;
  width: 200px;
  height: 150px;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 0px 0px 7px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.hidden-menu-toggle {
    height: 100%;
    transition-timing-function: ease-in;
    transition: 0.7s;
    border-radius: 0px;
  }
`;
