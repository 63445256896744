// Library Imports
import React from "react";
import { connect } from "react-redux";

// Assets $ Style Imports
import { Doughnut } from "react-chartjs-2";
import { ColumnContainer, P } from "./_Breakeven_Styles";

let fakeData = {
	labels: [],

	datasets: [
		{
			data: [44],
			backgroundColor: ["#446A7D", "#B17373", "#8C5A5A"],
			hoverBackgroundColor: ["#365565", "#8C5A5A", "#8C5A5A"],
		},
	],
};

class FixedPieChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			color: "",
			itemName: "",
		};
	}
	componentDidMount() {
		fakeData.labels = [];
		fakeData.datasets[0].data = [];
		console.log(this.props.state.breakeven_data.breakeven_data.fixed_expenses);
		this.props.state.breakeven_data.breakeven_data.fixed_expenses.map(
			(item, index) => {
				fakeData.labels.push(item.transaction_name);
				fakeData.datasets[0].data.push(item.amount);
			}
		);
	}

	changeColor = (prop) => {
		this.setState({ color: prop });
	};

	hoverMode = (item) => {
		this.setState({ color: item });
		this.props.getColor(item);
	};

	render() {
		return (
			<ColumnContainer>
				<P theme={{ fontSize: "36px" }}> Fixed Costs</P>
				<div>
					<Doughnut
						width={400}
						height={500}
						data={fakeData}
						onElementsClick={(elems) => {
							// OnClick gets that items info
							console.log(elems);
						}}
						options={{
							tooltips: {
								callbacks: {
									afterLabel: (item, data) => {
										// Hover grabs the color of the item
										this.hoverMode(
											data.datasets[0].backgroundColor[item.index]
										);
										this.setState({
											itemName: `${data.labels[item.index]}: ${
												data.datasets[0].data[item.index]
											}`,
										});
									},
								},
							},
							title: {
								display: true,
								text: this.state.itemName === "" ? "" : this.state.itemName,
								fontSize: 20,
								fontColor: this.state.color,
								padding: 0,
							},
							legend: {
								display: false,
								position: "right",
							},
						}}
					/>
				</div>
			</ColumnContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(FixedPieChart);
