// Dev Note: This is the 404
// Style

// Library Imports
import React from "react";
import NavBar from "./navBar/NavBar";

// Assets & Styles Imports
import "../styles/global.css";

export default class PageNotFound extends React.Component {
  render() {
    return (
      <div className="container--fullscreen">
        <NavBar />
        <div className="">
            <h1>We are still building this stuff</h1>
        </div>
      </div>
    );
  }
}
