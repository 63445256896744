// Dev Note: This will be the modal for the equipment.
// Set up to receive data from the backend
// Style

// Library Imports
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ScrollAnimation from "react-animate-on-scroll";

// Assets $ Style Imports
import NavBar from "../navBar/NavBar.js";
import { EndpointContainer } from "../global/_EndpointContainer.js";
import { EndpointDisplay } from "../global/_EndpointDisplay.js";
import { TabWrapper } from "../global/_TabWrapper.js";
import EquipmentProjections from "./EquipmentProjections"

export default class Equipment extends React.Component {
  render() {
    return (
      <EndpointContainer>
        <NavBar />
        <ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
          <EndpointDisplay>
            <h1>Equipment</h1>
            <TabWrapper>
              <Tabs>
                <TabList>
                  <Tab>Projection Overview</Tab>
                </TabList>
                <TabPanel>
                  <EquipmentProjections />
                </TabPanel>
              </Tabs>
            </TabWrapper>
          </EndpointDisplay>
        </ScrollAnimation>
      </EndpointContainer>
    );
  }
}
