import styled from "styled-components";

export const Estimates_Div_Section = styled.div`
    display: flex;
    width: 99%;
    justify-content: flex-start;
    align-items: center;
    padding-left 20%;
    transition: .7s ease;
    border-bottom: .1px solid gray;
    font-weight: normal;
    &:hover {
        // background-color: #16455D;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
        transform: scale(1.05);
        font-weight: normal;
    }
    a {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    img {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 30px;
    }

    h4 {
        font-size: 16px;
        color: #000;
        margin-top: 12px;
        margin-bottom: 0px;
    }
`;
