// Dev Note: Probably will need some Cognito put here in addition
// to Private Route set up.

import React, { Component } from "react";

// import BrowserRouter as Router, Route from react-router-dom
import "./index.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Splash from "./components/Splash";
import Summary from "./components/summary/Summary";
import PageNotFound from "./components/PageNotFound";
import Landing from "./components/landing/Landing";
import Login from "./components/Login";
import BreakEven from "./components/breakeven/Breakeven.js";
import FinancialGrowth from "./components/financialGrowth/FinancialGrowth.js";
import OfficerSalary from "./components/officerSalary/OfficerSalary.js";
import Employee from "./components/employee/Employee";
import Equipment from "./components/equipment/Equipment";
import RealEstate from "./components/realEstate/RealEstate";
import Profile from "./components/profile/Profile";
import Welcome from "./components/Welcome";

class App extends Component {
	render() {
		return (
			<Router>
				<Route path="/" exact component={Landing} />
				<Route path="/login" exact component={Login} />
				<Route path="/loading" exact component={Splash} />
                <Route path="/welcome" exact component={Welcome} />
				<Route path="/app-main" exact component={Summary} />
				{/* Routes for each component */}
				<Route path="/app-main/profile" exact component={Profile} />
				<Route path="/app-main/breakeven" exact component={BreakEven} />
				<Route path="/app-main/financials" exact component={FinancialGrowth} />
				<Route path="/app-main/salary" exact component={OfficerSalary} />
				<Route path="/app-main/employee" exact component={Employee} />
				<Route path="/app-main/equipment" exact component={Equipment} />
				<Route path="/app-main/realestate" exact component={RealEstate} />

				<Route path="/404" exact component={PageNotFound} />
			</Router>
		);
	}
}

export default App;
