import React from "react";
import PieChart from "./PieChart";
import MonthlyDebtContent from "./MonthlyDebtContent";
import { RowCenter, ColumnCenter } from '../../global/_GlobalStyles'
import FinancialHealthInfo from "./FinancialHealthInfo"

class MonthlyDebtTab extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
            <ColumnCenter margin="0 0 70px 0"> 
                <RowCenter width="95%">
                    <PieChart />
                    <MonthlyDebtContent handleButtonClick={this.props.handleButtonClick} />
                </RowCenter>
                <FinancialHealthInfo/>
            </ColumnCenter>
		);
	}
}

export default MonthlyDebtTab;
