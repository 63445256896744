import React from "react";
import { Bar } from "react-chartjs-2";
import {BarWidth, P, SpaceAround} from '../../global/_GlobalStyles'

let OfficerGrowth = {
    currentAmount: "$90,000",
    suggested: "$133,800",
    difference: "$43,800"
}

const data = {
	labels: [`${OfficerGrowth.currentAmount} Current`, `${OfficerGrowth.suggested} Suggested`, `${OfficerGrowth.difference} Difference`],
	datasets: [
		{
            backgroundColor: ["#446A7D", "#B17373", "#92B173"],
			borderColor: "rgba(0,0,0,1)",
			borderWidth: 2,
			data: [65, 59, 80, 81, 56],
		},
	],
};
class BarGraph extends React.Component {
	render() {
		return (
			<BarWidth>
				<Bar
                   data={data}
                   options={{
                        legend: false
                   }}
				/>
			</BarWidth>
		);
	}
}

export default BarGraph;
