import styled from "styled-components";

// FlexBoxing Styles

export const RowCenter = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
	}};
	display: flex;
    flex-direction: row;
    align-items: ${(props) => {
        return props.alignItems ? props.alignItems : "center";
    }};
    justify-content: center;
    padding: ${(props) => {
        return props.padding ? props.padding : "";
	}};
`;

export const SpaceBetween = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
	}};
	display: flex;
	flex-direction: row;
    justify-content: space-between;
    border-bottom: ${(props) => {
        return props.borderBottom ? props.borderBottom : "";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
	}};
`;

export const SpaceAround = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
	}};
	display: flex;
	flex-direction: row;
    justify-content: space-evenly;
    border-bottom: ${(props) => {
        return props.borderBottom ? props.borderBottom : "";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
	}};
`;
export const FlexStart = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
	}};
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "";
	}};
`;

export const FlexEnd = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
	}};
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "";
	}};
`;

export const ColumnCenter = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
    }};
    height: ${(props) => {
		return props.height ? props.height : "";
	}};
	display: flex;
    flex-direction: column;
    align-items: ${(props) => {
        return props.alignItems ? props.alignItems : "center";
    }};
    justify-content: center;
    position: ${(props) => {
        return props.position ? props.position : "";
	}};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "";
    }};
    transition: .3s ease;
`;

export const Card = styled.div`
    width: ${(props) => {
		return props.width ? props.width : "100%";
    }};
    height: ${(props) => {
		return props.height ? props.height : "";
    }};
    display: flex;
    flex-direction: ${(props) => {
		return props.flexDirection ? props.flexDirection : "row";
    }};
    justify-content:  ${(props) => {
		return props.justifyContent ? props.justifyContent : "center";
    }};
    padding:  ${(props) => {
		return props.padding ? props.padding : "";
    }};
    align-items: ${(props) => {
		return props.alignItems ? props.alignItems : "center";
    }};
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    box-shadow: 0px 3px 15px #E1E9F1;
    border-radius: 10px;
    margin: ${(props) => {
        return props.margin ? props.margin : "0";
    }};
`
export const Porter = styled.p`
	font-family: porter_sans;
	text-transform: uppercase;
	font-size: ${(props) => {
		return props.fontSize ? props.fontSize : "18px";
	}};
	color: ${(props) => {
		return props.color ? props.color : "black";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "0";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
`;

export const P = styled.p`
	font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    text-align: center;
    width: ${(props) => {
		return props.width ? props.width : "";
    }};
    display: flex;
    justify-content: ${(props) => {
		return props.justifyContent ? props.justifyContent : "";
	}};
	font-size: ${(props) => {
		return props.fontSize ? props.fontSize : "18px";
	}};
	color: ${(props) => {
		return props.color ? props.color : "black";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "0";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
    font-weight: ${(props) => {
        return props.fontWeight ? props.fontWeight : "";
    }};
`;


export const BarWidth = styled.div`
	width: 100%;
	display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: space-around;
`;

export const More_Button = styled.div`
	width: 120px;
    height: 32px;
    font-size: 15px;
	background: #446a7d;
	border-radius: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
    color: white;
    text-transform: uppercase;
	transition: 0.3s ease;
    margin: ${(props) => {
        return props.margin ? props.margin : "";
    }};

	&:hover {
		cursor: pointer;
		background: #69c8ff;
	}
`;

export const Image = styled.img`
    width: ${(props) => {
        return props.width ? props.width : "5em";
    }};
    transform: ${(props) => {
        return props.rotate ? props.rotate : "";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
`

export const Score = styled.div`
    width: 80px;
    height: 22px;
    color: white;
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    display: flex;
    justify-content: space-around   ;
    align-items: center;
    
    /* Dull-Red */
    background-color: #B17373;
    border-radius: 7px;
`
export const Hyperlink = styled.div`
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    text-align: center;
    text-decoration: underline;
	font-size: ${(props) => {
		return props.fontSize ? props.fontSize : "18px";
	}};
	color: ${(props) => {
		return props.color ? props.color : "black";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "0";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
    font-weight: ${(props) => {
        return props.fontWeight ? props.fontWeight : "";
    }};
    cursor: pointer;
    color: #17455D;
`

export const RectangleBox = styled.div`
    width: 80%;
    padding: 25px;
    height: auto;
    margin: 25px 0;
    background: ${(props) => {
        return props.background ? props.background : "";
    }};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 33px;
`

export const InputBox = styled.input`
    width: ${(props) => {
		return props.width ? props.width : "100%";
    }};
    height: ${(props) => {
		return props.height ? props.height : "";
    }};
    outline: none;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px #B8B8B8;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: ${(props) => {
        return props.alignItems ? props.alignItems : "center";
    }};
    justify-content:  ${(props) => {
		return props.justifyContent ? props.justifyContent : "center";
    }};
    color: #767676;
    font-size: 24px;
    padding: 0 25px;

    ::placeholder {
        color: #CBCBCB;
        font-size: 24px;
    }
    ::focus {
        border: none;
        outline: none;
        box-shadow: 0px 3px 15px blue;
}
`

export const InputBoxNoBorder = styled.input`
    width: ${(props) => {
		return props.width ? props.width : "80%";
    }};
    height: ${(props) => {
		return props.height ? props.height : "";
    }};
    outline: none;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: ${(props) => {
        return props.alignItems ? props.alignItems : "center";
    }};
    justify-content:  ${(props) => {
		return props.justifyContent ? props.justifyContent : "center";
    }};
    color: #767676;
    font-size: 24px;
    padding: ${(props) => {
		return props.padding ? props.padding : " 0 25px";
    }};

    ::placeholder {
        color: #CBCBCB;
        font-size: 24px;
    }
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::focus {
        border: none;
        outline: none;
        box-shadow: 0px 3px 15px blue;
}
`

export const InputSpaceBetween = styled.div`
    width: 93%;
    height: ${(props) => {
		return props.height ? props.height : "";
    }};
    outline: none;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px #B8B8B8;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: ${(props) => {
        return props.alignItems ? props.alignItems : "center";
    }};
    justify-content:  ${(props) => {
		return props.justifyContent ? props.justifyContent : "center";
    }};
    color: #767676;
    font-size: 24px;
    cursor:pointer;

    ::placeholder {
        color: #CBCBCB;
        font-size: 24px;
    }
    ::focus {
        border: none;
        outline: none;
        box-shadow: 0px 3px 15px blue;
}
`

export const ButtonPrimary = styled.div `
    background: #446A7D;
    border-radius: 17px;
    cursor: pointer;
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    text-align: center;
    width: ${(props) => {
		return props.width ? props.width : "";
    }};
    display: flex;
    justify-content: ${(props) => {
		return props.justifyContent ? props.justifyContent : "";
	}};
	font-size: ${(props) => {
		return props.fontSize ? props.fontSize : "18px";
	}};
	color: ${(props) => {
		return props.color ? props.color : "black";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "0";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
    font-weight: ${(props) => {
        return props.fontWeight ? props.fontWeight : "";
    }};

`