import React from 'react'
import CreditScore from "./CreditScore"
import { ColumnCenter, Image } from "../../global/_GlobalStyles"

class LeftCard extends React.Component{
    render(){
        return(
            <ColumnCenter padding="25px 0" width="40%">
                <CreditScore />
                 <Image width="20em" src={this.props.src} margin="25px 0"/>
            </ColumnCenter>
        )
    }
}

export default LeftCard