import styled from "styled-components";

export const Breakeven_Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;

	@media only screen and (max-width: 970px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;
export const Breakeven_Info_Container = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
export const Cost_Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border-bottom: 1px solid gray;
`;

export const ColumnContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.padding {
		padding: 50px;
		padding-right: 180px;
	}
`;

export const ColumnContainer90 = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

export const Row_Container = styled.div`
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const RowCenter = styled.div`
	width: ${(props) => {
		return props.width ? props.width : "100%";
    }};
	display: flex;
	flex-direction: row;
	justify-content: center;
`;
export const RowSpaceBetween = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const More_Button = styled.div`
	width: 94px;
	height: 32px;
	background: #446a7d;
	border-radius: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	transition: 0.3s ease;

	&:hover {
		cursor: pointer;
		background: #69c8ff;
	}
`;

export const Row_Centered = styled.div`
	display: flex;
	width: 100%;
	justify-content: ${(props) => {
		return props ? props.theme.justifyContent : "space-around";
    }};
	align-items: ${(props) => props.theme.alignItems};
	border-bottom: ${(props) => props.theme.borderBottom};
	box-shadow: ${(props) => props.theme.boxShadow};
`;

export const FixedContainer = styled.div`
	display: flex;
	width: 85%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    height: auto;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
    border-radius: 8px;
    padding: 15px;
`;

export const Flex_Start = styled.div`
	display: flex;
	flex-direction: flex-start;
	justify-content: ${(props) => {
		return props ? props.theme.justifyContent : "flex-start";
	}};
	width: ${(props) => {
		return props.width ? props.width : "100%";
    }};
	padding: ${(props) => {
		return props ? props.theme.padding : "";
	}};
	border-right: ${(props) => {
		return props ? props.theme.borderRight : "";
	}};
`;
export const P = styled.p`
	font-family: "Ubuntu", sans-serif;
	text-transform: uppercase;
	font-size: ${(props) => {
		return props.fontSize ? props.fontSize : "18px";
	}};
	color: ${(props) => {
		return props.color ? props.color : "black";
    }};
    margin: ${(props) => {
        return props.margin ? props.margin : "0";
    }};
    padding: ${(props) => {
        return props.padding ? props.padding : "";
    }};
`;
// Define what main theme will look like

export const DropDown = styled.div`
	width: 80%;
	height: auto;
	margin: 0 auto;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: hidden;
	padding: 15px 0;
`;

export const Image = styled.img`
	z-index: 4;
    padding: 0 15px 0 0;
    height: ${(props) => {
		return props ? props.height : "";
    }};
`;

export const SpaceBetween = styled.div`
    display: flex;
    align-items: center;
	justify-content: space-between;
    width: 100%;
    padding: ${(props) => {
		return props ? props.padding : "";
    }};
    border-bottom: ${(props) => {
		return props ? props.borderBottom : "";
    }};
`;

export const DropDownRow = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	width: 100%;
	position: relative;
`;
export const DropDownInput = styled.div`
	width: 100%;
	font-size: 25px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: #16455d;
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;

	color: white;
	text-shadow: 0px 0px 1px #fff;
	outline: none;
	position: absolute;

	border: 0.3px solid black;
	::placeholder,
	::-webkit-input-placeholder {
		color: white;
		opacity: 0.8;
		font-weight: 10;
	}
`;

export const ValueText = styled.div`
	padding-left: 15px;
	opacity: ${(props) => {
		return props.theme.opacity ? props.theme.opacity : ".6";
	}};
`;
export const DropDownColumn = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;
	border-top: 0.2px solid gray;
	align-items: center;
	justify-content: flex-start;
	background-color: #16455d;
	border-bottom-left-radius: 5px;
	padding: 15px;
	/* width: 600px; */
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
	height: ${(props) => {
		return props.height ? props.height : "200px";
	}};
	box-shadow: 8px 4px 8px rgba(0, 0, 0, 0.8);

	::-webkit-scrollbar-track {
		position: absolute;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #16455d;
	}

	::-webkit-scrollbar {
		position: absolute;
		width: 12px;
		background-color: #16455d;
	}

	::-webkit-scrollbar-thumb {
		position: absolute;
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #ffffff;
	}
`;

export const Options = styled.option`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	color: white;
	font-size: 20px;
	padding: 15px;
	transition: 0.2s ease;
	cursor: pointer;

	&:hover {
		background-color: #6ac8ff;
	}
`;

export const Submit = styled.div`
	background-color: #58c347;
	color: white;
	width: 248px;
	height: 42px;
	border-radius: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 44px;
	cursor: pointer;
	transition: 0.4s ease;
	opacity: ${(props) => props.opacity};

	&:hover {
		background-color: #52b143;
	}
`;

export const ContainerColumn = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: space-between;
`;
export const RowFlexEnd = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const UnsortedP = styled.p`
	/* transition: 0.5s ease; */
	font-size: ${(props) => props.fontSize};
	opacity: ${(props) => props.opacity};
	display: ${(props) => props.display};
`;

export const Refresh = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justifyConent};
	padding: 0 10px;
	height: 50px;
	border: 1px solid #16455d;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 17px;
	transition: 0.5s ease;
	width: ${(props) => props.width};
	overflow: hidden;
	&:hover {
		cursor: pointer;
	}
	&:active {
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
	}
`;

export const RefreshIcon = styled.img`
	height: 1.5em;
	/* padding-left: 10px; */
	transition: 1s ease;
	animation: ${(props) => props.spin} @keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
`;

export const AllGoodText = styled.p`
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: Ubuntu;
	font-style: normal;
	font-weight: normal;
	font-size: 57px;
	line-height: 65px;
	text-align: center;
	text-transform: uppercase;
	color: #434343;
`;

export const AllGoodTextColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding: 45px;
`;
export const HumanImage = styled.img`
	display: flex;
	justify-content: flex-end;
	width: 9em;
	padding-right: 25px;
`;

export const TransactionTitlesDiv = styled.div `
    width: 100%;
    display: flex;
    // justify-content: space-around;
    border-top: 1px solid #9B9B9B;
    border-bottom: 1px solid #9B9B9B;
    margin: 15px 0 0 0;
`

export const NextButton = styled.div `
    width: 105px;
    height: 35px;
    cursor: pointer;
    color: white;
    display: flex; 
    justify-content: center;
    align-items: center;
    background: #0078BD;
    border: 1px solid #434343;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    	&:active {
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
	}
`

export const NextButtonContainer = styled.div   `
    display: flex;
    width: 100%;
    margin: 35px 25px 25px;
    justify-content: flex-end;
`