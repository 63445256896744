import React from "react";
import {
    SpaceBetween,
    FixedContainer,
    Row_Centered,
    Image,
    RowCenter,
	P,
	Flex_Start,
} from "./_Breakeven_Styles";
import { connect } from "react-redux";
import TransactionTitles from './TransactionTitles'
import VariableTransactionItems from './VariableTransactionItems.js'
import { ColumnCenter } from "./_ModalStyles";
import MoneyGrow from '../../assets/moneyGrow.png'


class Variable extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		console.log(this.props.state);
	}
	render() {
		return (
			<FixedContainer>
                <SpaceBetween>
                    <Row_Centered>
                        <Image src={MoneyGrow} height="4em" alt="Dollar Coin" />
                        <ColumnCenter> 
                        <Flex_Start>
                            <P fontSize= "26px;" color= "#414042" > Variable Costs</P>
                        </Flex_Start>
                        <Flex_Start>
                            <P fontSize= "26px;" color= "#414042" >
                                {this.props.state.breakeven_data.breakeven_data.variable_costs}{" "}
                            </P>
                        </Flex_Start>
                        </ColumnCenter>
                    </Row_Centered>
                    <ColumnCenter width="304px">
                        <RowCenter >
                            <P fontSize= "44px;" color= "#414042"> 55%</P>
                        </RowCenter>
                        <RowCenter> 
                            <P fontSize= "16px;" color= "#414042" >
                                of your breakeven
                            </P>
                        </RowCenter>
                    </ColumnCenter>
                </SpaceBetween>
                <TransactionTitles/>
                <VariableTransactionItems />
			</FixedContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(Variable);
