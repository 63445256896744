import styled from "styled-components";

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  width: 99%;

  h1 {
    font-size: 20px;
    margin-top: 18px;
  }

  img {
    margin-top: 2px;
  }

  .GoHome {
    position: absolute;
    top: 150px;
    left: 0px;
    display: flex;
    width: 99%;
    height: 50px;
    justify-content: flex-start;
    align-items: center;
    padding-left 30%;
    transition: .7s ease;
    border-bottom: .1px solid gray;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    &:hover {
        // background-color: #16455D;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
        transform: scale(1.1)
        font-weight: normal;
    }
  }
`;
