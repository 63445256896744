import styled from "styled-components";
import bgImg from "../../assets/summaryBackground.svg";

export const SummaryContainer = styled.div`
  width: 100vw;
  background-image: url(${bgImg});
  background-position: center;
  background-repeat: repeat;

  .summary-title {
    width: 315px;
    height: 53px;
    margin-top: 20px;
    font-size: 50px;
    line-height: 69px;
    text-align: center;
    text-transform: uppercase;
  }

  a {
      color: black;
  }
`;

export const BreakevenCard = styled.div`
  height: 300px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  margin-top: 5px;
  margin-right: 38px;
  margin-bottom: 38px;
  transition: 1s ease;
  position: absolute;

  &:hover {
    transform: scale(1.01);
  }

  p {
      text-align: center;
      margin-bottom: 20px;
  }
`;

export const CardContainer = styled.div`
  width: 1200px;
  margin-top: 40px;
  margin-left: 270px;
  height: 800px;
  position: relative;

  @media only screen and (max-width: 1300px) {
    width: 1000px;
    height: 1200px;
  }

  @media only screen and (max-width: 970px) {
    width: 700px;
    height: 2100px;
  }

  h1 {
      text-align: center;
      font-size: 24px;
  }
`;

export const ContentCointainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmployeeCard = styled.div`
  height: 300px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  margin-top: 5px;
  margin-right: 38px;
  margin-bottom: 38px;
  transition: 1s ease;

  &:hover {
    transform: scale(1.01);
  }

  // positioning
  position: absolute;
  top: 340px;

  @media only screen and (max-width: 1300px) {
    top: 340px;
    left: 340px;
  }

  @media only screen and (max-width: 970px) {
    top: 1020px;
    left: 0;
  }
`;

export const EquipmentCard = styled.div`
  height: 300px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  margin-top: 5px;
  margin-right: 38px;
  margin-bottom: 38px;
  transition: 1s ease;

  &:hover {
    transform: scale(1.01);
  }

  // positioning
  position: absolute;
  top: 340px;
  left: 340px;

  @media only screen and (max-width: 1300px) {
    top: 680px;
    left: 0px;
  }

  @media only screen and (max-width: 970px) {
    top: 1360px;
    left: 0;
  }
`;

export const GrowthCard = styled.div`
  height: 300px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  margin-top: 5px;
  margin-right: 38px;
  margin-bottom: 38px;
  transition: 1s ease;

  &:hover {
    transform: scale(1.01);
  }

  // positioning
  position: absolute;
  left: 680px;

  @media only screen and (max-width: 1300px) {
    top: 340px;
    left: 0px;
  }

  @media only screen and (max-width: 970px) {
    top: 680px;
    left: 0;
  }
`;

export const OfficerCard = styled.div`
  height: 300px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  margin-top: 5px;
  transition: 1s ease;

  &:hover {
    transform: scale(1.01);
  }

  // positioning
  position: absolute;
  left: 340px;

  @media only screen and (max-width: 970px) {
    top: 340px;
    left: 0;
  }
`;

export const RealEstateCard = styled.div`
  height: 300px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  margin-top: 5px;
  margin-right: 38px;
  margin-bottom: 38px;
  transition: 1s ease;

  &:hover {
    transform: scale(1.01);
  }
  
  // positioning
  position: absolute;
  top: 340px;
  left: 680px;

  @media only screen and (max-width: 1300px) {
    top: 680px;
    left: 340px;
  }

  @media only screen and (max-width: 970px) {
    top: 1700px;
    left: 0;
  }
`;
