import React from "react";
import LeftCard from "./LeftCard";
import RevolvingAccts from "./RevolvingAccts";
import { RowCenter } from '../../global/_GlobalStyles';
import CCFeatureImg from '../../../assets/CCFeatureImg.png'

class Revolving extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<RowCenter alignItems="flex-start" >
				<LeftCard src={CCFeatureImg}/>
				<RevolvingAccts />
			</RowCenter>
		);
	}
}

export default Revolving;
