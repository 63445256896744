
import React from 'react'
import Arrow from "../../assets/GrayArrow.png";
import {Card, P, InputBoxNoBorder, ButtonPrimary, FlexStart, Image, InputBoxNoBorderNoBorder, InputSpaceBetween, TermToggler, RowCenter, ColumnCenter, SpaceAround} from '../global/_GlobalStyles'
import "../../styles/animations/transition.css";

class InputCard extends React.Component {
    constructor(props){
        super(props)
        this.state={
            years:"Years",
            months:"Months",
            termTime: "Years",
            amount: "",
            apr: "",
            term: "",
            toggler: false,
            dropdown: "empty",
            styleBox: "",
            dropdownText: "Years",
            selectedText: "Months"
        }
    }

    toggleState(e){
         this.setState({[e.target.name]: e.target.value});
    }

    toggleDropdown(){
        this.state.dropdown === "empty" ? 
            this.setState({...this.state, dropdown: "bounce", styleBox: "dropdownColumnFillBackground"}) : 
            this.setState({...this.state, dropdown: "empty", styleBox: ""}) 
    }

    async toggleText(e){
        e.preventDefault()
        if(this.state.dropdownText === "Years"){
            //  For some reason the state doesn't update unless i have the toggler boolean state...odd
            await this.setState({...this.state, toggler: true})
            await this.setState({...this.state, dropdownText: "Months", selectedText: "Years"})
       } else{
           await this.setState({...this.state, toggler: false})
            await this.setState({...this.state, dropdownText: "Years", selectedText: "Months"}) 
       }
    }

    render(){
 
        return(
            <Card margin="25px" flexDirection="column" width="476px" justifyContent="space-around" height="548px" padding="25px" > 
                <P fontSize="30px">Loan Information</P>

                <InputSpaceBetween> 
                    <P width="30%" fontSize="24px" justifyContent="flex-end" color="#767676">$</P> 
                    <InputBoxNoBorder onChange={this.toggleState.bind(this)} value={this.state.amount} name="amount" type="number" padding="0 0 0 5px" placeholder="LOAN AMOUNT" width="343px" height= "59px" />
                </InputSpaceBetween>

                <InputSpaceBetween> 
                    <InputBoxNoBorder onChange={this.toggleState.bind(this)} value={this.state.apr} name="apr" type="number" padding="0 0 0 5px" justifyContent="flex-end"  placeholder="INTEREST RATE" width="50%" height= "59px" />
                    <P fontSize="24px" color="#767676">%</P> 
                </InputSpaceBetween>

                <InputSpaceBetween height="59px">
                 {/* To make a clean animation...I had to use CSS instead of Styled Components */}
                    <InputBoxNoBorder onChange={this.toggleState.bind(this)} value={this.state.term} name="term" type="number" placeholder="LOAN TERM" width="71%" height= "59px" />
                        <div className="TermToggler" onClick={this.toggleDropdown.bind(this)} > 
                            <div className={`dropdownColumn ${this.state.styleBox}`} > 
                                <FlexStart>
                                    <P fontSize="24px" color="#767676">{this.state.selectedText}</P> 
                                    <Image src={Arrow} alt="arrow" width="1em" padding="0 0 0 10px" />
                                </FlexStart>
                                <div onClick={this.toggleText.bind(this)}  style={{width: "100%"}}> 
                                <p className={`term-dropdown ${this.state.dropdown}`}>
                                    {this.state.dropdownText}
                                </p> 
                                </div>
                            </div>
                        </div>
                </InputSpaceBetween>
                <ButtonPrimary color="white" padding="15px">Calculate</ButtonPrimary>
            </Card>
        )
    }
}

export default InputCard;