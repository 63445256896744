import React, { Component } from "react";
import { PlaidLink } from "react-plaid-link";
import axios from "axios";

class Link extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transactions: [],
            link_token: "link-sandbox-463c0470-329c-4de1-bc48-9014ebd8baac",
            public_token: ""
        };

        this.handleClick = this.handleClick.bind(this);
    }

    onSuccess = async (public_token) => {
        this.setState({ public_token })
        // send token to client server
        await axios.post("http://localhost:3000/get_access_token", { public_token: public_token }).then(res => {
            console.log(res.data)
        })
            .catch(err => {
                console.log(err)
            })
    }

    handleOnExit() {
        // handle the case when your user exits Link
        // For the sake of this tutorial, we're not going to be doing anything here.
    }

    handleClick(res) {
        axios.get("http://localhost:3000/accounts").then(res => {
            // this.setState({ transactions: res.data });
            console.log(res.data)
        });
        // "access-sandbox-e93ff482-0556-4ded-8085-0e3540bebf46"
        // axios.get("")
    }

    getLink = async () => {
        let token
        await axios.post("https://3000-a1aaee3c-8f37-41ca-8b42-8ef57b5f1a34.ws-us03.gitpod.io/get_link_token")

            .then(res => {
                // success(token)
                token = res.data.link_token
                // console.log(this.handleOnSuccess(res.data.link_token))
            }).catch(err => {
                console.log(err)
            })
        console.log(token)
        this.setState({ link_token: token })
    }

    render() {
        return (
            <div>
                <PlaidLink
                    token={this.state.link_token}
                    onSuccess={this.onSuccess}
                >
                    Connect a bank account
                </PlaidLink>
                <div>
                    <button onClick={this.handleClick}>Get Accounts</button>
                </div>
                <div>
                    <button onClick={this.getLink}>Get Link</button>
                </div>
            </div>
        );
    }
}

export default Link;


