import React from "react";
import {Card, ColumnCenter, Porter, P, SpaceBetween, More_Button} from '../../global/_GlobalStyles'

class OverviewInfo extends React.Component {
    constructor(props){
        super(props)
    }
	render() {
		return (
            <Card margin="25px">
                <ColumnCenter>
                    <ColumnCenter padding="35px 0"> 
                        <Porter fontSize="28px">Factors</Porter>
                        <P  color= "#FF7474">Monthly debt + living expenses = Suggested </P>
                    </ColumnCenter>
                    <SpaceBetween width="80%" padding="14px 0" borderBottom="1px solid #9B9B9B">
                        <P>Total Monthly Debt:</P>
                        <P color="#FF7474" fontWeight="bold">$90,000</P>
                    </SpaceBetween>
                    <SpaceBetween width="80%" padding="14px 0" borderBottom="1px solid #9B9B9B">
                        <P>Living Expenses:</P>
                        <P color="#FF7474" fontWeight="bold">$43,800</P>
                    </SpaceBetween>
                    <SpaceBetween width="80%" padding="14px 0">
                        <P>Suggested:</P>
                        <P color="#84BF49" fontWeight="bold">$133,800</P>
                    </SpaceBetween>
                <Card width="70%" margin="35px 0">
                    <ColumnCenter padding="15px">
                        <P>It looks like you need to pay yourself more!</P>
                        <More_Button margin="15px 0 0 0" onClick={()=>this.props.handleButtonClick(1)}>Learn More</More_Button>
                    </ColumnCenter>
                </Card>
                </ColumnCenter>
            </Card>
	)}
}

export default OverviewInfo;
