// Dev Note: This will eventually serve as the loading splash
// screen after log in

// Library Imports
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Welcome from "./Welcome"
import { login_user } from "../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Assets & Styles Imports
import "../styles/global.css";
import logo from "../assets/logoPrimary.svg";

class Splash extends React.Component {
  constructor(props){
      super(props)
      
      this.state={
          transition: "fadeIn"
      }
  }
  async componentDidMount(){
    let credentials= {
            email: "thejobs@gmail.com",
            password: "number1"
        }

        localStorage.clear();
        localStorage.removeItem("token");
        console.log()
        await this.props.login_user(credentials);
    
        if (localStorage.getItem("token")) {
             this.setState({transition: "fadeOut"})
            console.log("works!");
        } else {
            alert(
                "Something happend, and we are on it."
            );
        }
        console.log(this.props.state);
    };
  

  render() {

    if(this.state.transition === "fadeOut"){
        setTimeout(()=> {
          this.props.history.push('/welcome')
      }, 2000) 
    }
      
    return (
      <div className= {`container--splash container--fullscreen ${this.state.transition}` } >
           <p className="Text">Loading</p>
        <div className="splashBar--container">
          <div className="splashBar splashBar--light"></div>
          <div className="splashBar splashBar--dark"></div>
        </div>
        <div className="splashBox--container">
          {/* Row 1 */}
          <div className="splashBox splashBox--dark9s"></div>
          <div className="splashBox splashBox--light4s"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light15s"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light8s"></div>
          {/* Row 2 */}
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
          {/* Row 3 */}
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light6s"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light"></div>
          {/* Row 4 */}
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
          <div className="splashBox splashBox--light"></div>
          <div className="splashBox splashBox--dark"></div>
        </div>
   
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ state });

export default withRouter(connect(mapStateToProps, { login_user })(Splash));