import React from "react";
import { CtaContainer } from "./LandingStyles";
import { Icon } from "@iconify/react";
import chevronDoubleUp from "@iconify/icons-cil/chevron-double-up";

class OntheWay extends React.Component {
  render() {
    return (
      <CtaContainer>
        <div className="CtaText">
          <h2>YOUR VIRTUAL CFO</h2>
        </div>
        <Icon
          color="#fff"
          icon={chevronDoubleUp}
          vFlip={true}
          style={{
            width: "4.5em",
            height: "4.5em",
            marginBottom: "1em",
            cursor: "pointer",
          }}
        />
      </CtaContainer>
    );
  }
}

export default OntheWay;
