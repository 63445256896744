import React from "react";
import PieChart from "./PieChart";
import Breakeven_Info from "./Breakeven_Info";
import { Breakeven_Container } from "./_Breakeven_Styles";

class Breakeven_Tab extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Breakeven_Container>
				<PieChart />
				<Breakeven_Info handleButtonClick={this.props.handleButtonClick} />
			</Breakeven_Container>
		);
	}
}

export default Breakeven_Tab;
