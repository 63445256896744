import React from 'react'
import { ColumnCenter, SpaceBetween, P, FlexStart, FlexEnd } from '../../global/_GlobalStyles'
import { connect } from "react-redux"; 

class InstallmentAccts extends React.Component{
    state={
        creditData:[
            {
                name: "WF - Mortgage",
                balance: "$450,800.00",
                current: "$3,200.00",
                suggested: "$3,500.00",
                apr: "5%"
            }
        ]
    }
    render(){
        return(
            <ColumnCenter padding="0 41px 0 0">
            {/* {this.props.state.breakeven_data.breakeven_data.fixed_expenses.map(
                (transaction, index) => ( */}
                    <SpaceBetween padding="15px 0" borderBottom="1px solid #9B9B9B">
                        <FlexStart>
                            <P fontSize= "20px" color= "black">
                                {" "}
                                Account
                            </P>
                        </FlexStart>
                        <FlexStart>
                            <P fontSize= "20px" color= "black">
                                {" "}
                                Balance
                            </P>
                        </FlexStart>
                         <FlexStart >
                            <P fontSize= "20px" color= "black">
                                {" "}
                                Current <br/> Payment
                            </P>
                        </FlexStart>
                        <FlexStart >
                            <P fontSize= "20px" color= "black">
                                {" "}
                                Suggested <br/> Payment
                            </P>
                        </FlexStart>
                        <FlexStart >
                            <P fontSize= "20px" color= "black">
                                {" "}
                                APR
                            </P>
                        </FlexStart>
                    </SpaceBetween>
                {/* The start of displaying all the revolving credit details */}
                {this.state.creditData.map(item=> (
                    <SpaceBetween padding="15px 0" >
                        <FlexStart>
                            <P fontSize= "18px" color= "#6B6B6B">
                                {item.name}
                            </P>
                        </FlexStart>
                        <FlexStart>
                            <P fontSize= "18px" color= "#6B6B6B">
                                {item.balance}
                            </P>
                        </FlexStart>
                         <FlexStart >
                            <P fontSize= "18px" color= "#B17373">
                                {item.current}
                            </P>
                        </FlexStart>
                        <FlexStart >
                            <P fontSize= "18px" color= "#27AE60">
                                {item.suggested}
                            </P>
                        </FlexStart>
                        <FlexStart >
                            <P fontSize= "18px" color= "#6B6B6B">
                                {item.apr}
                            </P>
                        </FlexStart>
                    </SpaceBetween>
                ))}
            </ColumnCenter>
        )
    }
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(InstallmentAccts);
