import React from "react";
import {
	Row_Centered,
    FixedContainer,
    SpaceBetween,
    P,
    Image,
	Flex_Start,
    RowCenter,
} from "./_Breakeven_Styles";
import { connect } from "react-redux";
import { ColumnCenter } from "./_ModalStyles";
import Dollar from '../../assets/dollar1.png'
import TransactionTitles from './TransactionTitles'
import TransactionItems from './TransactionItems'

class Fixed extends React.Component {
	render() {
		return (
			<FixedContainer>
                <SpaceBetween>
                    <Row_Centered>
                        <Image src={Dollar} height="4em" alt="Dollar Coin" />
                        <ColumnCenter> 
                        <Flex_Start>
                            <P fontSize= "26px;" color= "#414042" > Fixed Costs</P>
                        </Flex_Start>
                        <Flex_Start>
                            <P fontSize= "26px;" color= "#414042" >
                                {this.props.state.breakeven_data.breakeven_data.fixed_costs}{" "}
                            </P>
                        </Flex_Start>
                        </ColumnCenter>
                    </Row_Centered>
                    <ColumnCenter width="304px">
                        <RowCenter >
                            <P fontSize= "44px;" color= "#414042"> 45%</P>
                        </RowCenter>
                        <RowCenter> 
                            <P fontSize= "16px;" color= "#414042" >
                                of your breakeven
                            </P>
                        </RowCenter>
                    </ColumnCenter>
                </SpaceBetween>
                <TransactionTitles/>
                <TransactionItems />
			</FixedContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(Fixed);
