// Dev Note: This will be the modal for the officer salary.
// Set up to receive data from the backend
// Style

// Library Imports
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ScrollAnimation from "react-animate-on-scroll";

// Assets $ Style Imports
import NavBar from "../navBar/NavBar.js";
import { EndpointContainer } from "../global/_EndpointContainer.js";
import { EndpointDisplay } from "../global/_EndpointDisplay.js";
import { TabWrapper } from "../global/_TabWrapper";
import Overview from "./OverviewTab/Overview.js";
import MonthlyDebtTab from "./MonthlyDebtTab/MonthlyDebtTab.js";
import Revolving from './RevolvingTab/Revolving.js'
import Installments from './InstallmentsTab/Installments'

export default class OfficerSalary extends React.Component {
    state = {
		selectedIndex: 0,
    };

    handleSelect = (index) => {
		this.setState({ selectedIndex: index });
    };
    
    handleButtonClick(tab_index) {
    // Sends user to the selected tab by passing the prop to the 'more' button
		this.setState({ selectedIndex: tab_index });
    }
    
	render() {
		return (
			<EndpointContainer>
				<NavBar />
				<ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
					<EndpointDisplay>
						<h1>Officer Salary</h1>
						<TabWrapper>
							<Tabs
                                selectedIndex={this.state.selectedIndex}
								onSelect={this.handleSelect}
                            >
								<TabList>
									<Tab>Overview</Tab>
									<Tab>Monthly Debt</Tab>
									<Tab>Credit Lines</Tab>
									<Tab>Installments</Tab>
								</TabList>
								<TabPanel>
									<Overview handleButtonClick={(tab) => this.handleButtonClick(tab)} />
								</TabPanel>
								<TabPanel>
									<MonthlyDebtTab handleButtonClick={(tab) => this.handleButtonClick(tab)}  />
								</TabPanel>
								<TabPanel>
									<Revolving />
								</TabPanel>
								<TabPanel>
									<Installments />
								</TabPanel>
							</Tabs>
						</TabWrapper>
					</EndpointDisplay>
				</ScrollAnimation>
			</EndpointContainer>
		);
	}
}
