import React from "react";
import logo from "../../assets/logoPrimary.svg";
import back2Top from "../../assets/back2Top.svg";
import { LandingNav, Back_To_Top_Button } from "./LandingStyles";

class MarketNav extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    window.onscroll = this.scrollFunction();
  }

  scrollFunction() {
    let scrollButton = document.getElementById("scrollButton");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        scrollButton.style.opacity = "1";
        scrollButton.style.cursor = "pointer";
      } else {
        scrollButton.style.opacity = "0";
        scrollButton.style.cursor = "default";
      }
    });
  }

  backToTop() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <LandingNav>
        <img className="navLogo" src={logo}/>
      <Back_To_Top_Button id="scrollButton" onClick={this.backToTop}>
        <img src={back2Top} alt="Button to go back to the top of the page" />
      </Back_To_Top_Button>
      </LandingNav>
    );
  }
}

export default MarketNav;
