import React from "react";
import {
	ColumnContainer,
	RowCenter,
	P,
	ColumnContainer90,
} from "./_Breakeven_Styles";
import { connect } from "react-redux";
import Dopdown from "./Dropdown";

class UnsortedTransactions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Empty: true,
			unsorted: [],
		};
	}
	async componentDidMount() {
		(await this.props.state.update_expenses_needed[0]) === undefined
			? this.setState({ Empty: true })
			: (this.state.unsorted = this.props.state.update_expenses_needed);
		this.setState({ Empty: false });
		console.log(this.state);
	}
	updatedExpense(expenseType) {
		console.log(expenseType);
	}

	render() {
		return (
			<ColumnContainer>
				{this.state.Empty
					? "not"
					: this.state.unsorted.map((item, index) => (
							<ColumnContainer90 key={index}>
								<RowCenter>
									<RowCenter>
										<P fontSize="15px">{item.transaction_name}</P>
									</RowCenter>
									<RowCenter>
										<P>{item.id}</P>
									</RowCenter>
									<RowCenter>
										<P>{item.amount}</P>
									</RowCenter>
									<RowCenter>
										<Dopdown
											key={index}
											updatedExpense={this.updatedExpense}
											itemInfo={item}
										/>
									</RowCenter>
								</RowCenter>
							</ColumnContainer90>
					  ))}
			</ColumnContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(UnsortedTransactions);
