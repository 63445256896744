import axios from 'axios';

import {FETCH_DATA, LOGIN} from "./types";

export function defaultFunction() {
  let hello = "hello";
  console.log(hello)

  return {
    type: FETCH_DATA,
    payload: hello,
  };
}

export const login_user = credentials => dispatch => {
  let id
  let all_the_datas = {}

  // Sends Multiple axios requests to send user's info to the redux store
  // Can DEFINETLEY be Re-factored...in the future.
  return axios
    .post("https://ledgerfire-be.herokuapp.com/users/login", credentials)
    .then(res => {
      localStorage.setItem("token", res.data.token);
      all_the_datas["user_info"] = res.data
      id = res.data.id
      return axios.get(`https://ledgerfire-be.herokuapp.com/api/checkTransactions/${id}`)
    })
    .then((res) =>{
      all_the_datas["update_expenses_needed"] = res.data
      return axios.get(`https://ledgerfire-be.herokuapp.com/calculate/getBreakeven/${id}`)
    })
    .then((res) => {
      all_the_datas["breakeven_data"] = res.data
      return axios.get(`https://ledgerfire-be.herokuapp.com/calculate/OfficerSalary/${id}`)
    })
    .then((res) => {
      all_the_datas["officer_salary"] = res.data
      dispatch({ type: LOGIN, payload: all_the_datas });
    })
 
};