import React from "react";
import { connect } from "react-redux";
import {
	Cost_Container,
	ColumnContainer,
	Row_Container,
	Breakeven_Info_Container,
	More_Button,
} from "./_Breakeven_Styles";

class Breakeven_Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// Keeping it DRY, grabbing the first 3 items to preview in the tab
		let {
			fixed_expenses,
			variable_expenses,
			breakeven,
			variable_costs,
			fixed_costs,
		} = this.props.state.breakeven_data.breakeven_data;

		let first_three_fixed_expenses = fixed_expenses.slice(0, 3);

		let first_three_variable_expenses = variable_expenses.slice(0, 2);

		return (
			<Breakeven_Info_Container>
				<ColumnContainer>
					<h2>CURRENT BREAKEVEN</h2>
					<h2> ${breakeven} </h2>
				</ColumnContainer>
				<Cost_Container>
					<h2> VARIABLE COSTS </h2>
					<h2>${variable_costs}</h2>
				</Cost_Container>
				<ColumnContainer>
					<Row_Container>
						{/* Manually had to add Officer Salary to show at the top of the list */}
						<p> OFFICER SALARY </p>
						<p>{this.props.state.breakeven_data.OfficerSalary}</p>
					</Row_Container>
					{/* Maps through the next two variable expenses */}
					{first_three_variable_expenses.map((expenses) => (
						<Row_Container>
							<p>{expenses.transaction_name}</p>
							<p>{expenses.amount}</p>
						</Row_Container>
					))}
				</ColumnContainer>
				<More_Button onClick={() => this.props.handleButtonClick(3)}>
					{/* onClick sends the number that redirects user to the corresponding tab */}
					MORE
				</More_Button>

				<Cost_Container>
					<h2> FIXED COSTS </h2>
					<h2>${fixed_costs}</h2>
				</Cost_Container>
				<ColumnContainer>
					{/* Maps through the first three fixed expenses */}
					{first_three_fixed_expenses.map((expenses) => (
						<Row_Container>
							<p>{expenses.transaction_name}</p>
							<p>{expenses.amount}</p>
						</Row_Container>
					))}
				</ColumnContainer>
				<More_Button onClick={() => this.props.handleButtonClick(2)}>
					{/* onClick sends the number that redirects user to the corresponding tab */}
					MORE
				</More_Button>
			</Breakeven_Info_Container>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(Breakeven_Info);
