import React from "react";
import { Row_Centered } from "./_Breakeven_Styles";
import FixedPieChart from "./FixedPieChart";
import Fixed from "./Fixed";
import { ColumnCenter } from "./_ModalStyles";
import InfoModal from './InfoModal'

class FixedCosts extends React.Component {
	state = {
		color: ""
	};

	changeColor = (hoveredColor) => {
		this.setState({ color: hoveredColor });
		console.log(this.state.color);
	};
	render() {
		return (
            <ColumnCenter padding = "0 0 50px 0" margin="0 0 50px 0">
                <Row_Centered theme={{ alignItems: "center", justifyContent: "center" }}>
                    <FixedPieChart getColor={(e) => this.changeColor(e)} />
                    <InfoModal/>
                </Row_Centered>
                <Fixed />
            </ColumnCenter>
		);
	}
}
export default FixedCosts;
