import React from "react";
import {
	DropDown,
	DropDownInput,
	ValueText,
	DropDownColumn,
	DropDownRow,
	Options,
	Image,
} from "./_Breakeven_Styles";
import Arrow from "../../assets/Arrow.png";

class Dropdown extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showMenu: false,
			expenseValue: "Select ",
			opacity: ".6",
		};

		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}
	componentDidMount() {
		console.log(this.props.itemInfo);
	}
	showMenu(event) {
		event.preventDefault();

		this.setState({ showMenu: true }, () => {
			document.addEventListener("click", this.closeMenu);
		});
	}

	closeMenu() {
		this.setState({ showMenu: false }, () => {
			document.removeEventListener("click", this.closeMenu);
		});
	}

	selected(name) {
		this.props.updatedExpense(name);
		this.setState({ expenseValue: name, opacity: "1" });
	}

	render() {
		return (
			<DropDown>
				<DropDownRow>
					<DropDownInput
						onClick={this.showMenu}
						value={this.state.expenseValue}
					>
						<ValueText theme={{ opacity: this.state.opacity }}>
							{this.state.expenseValue}
						</ValueText>
					</DropDownInput>
					<Image src={Arrow} alt="arrow" />
				</DropDownRow>

				{this.state.showMenu ? (
					this.props.itemInfo.type === "expense" ? (
						<DropDownColumn
							id="scroll"
							className="menu"
							ref={(element) => {
								this.dropdownMenu = element;
							}}
						>
							<Options onClick={() => this.selected("Inventory")}>
								Inventory
							</Options>
							<Options onClick={() => this.selected("Officer's Salary")}>
								{" "}
								Officer's Salary{" "}
							</Options>
							<Options onClick={() => this.selected("Payroll")}>
								{" "}
								Payroll{" "}
							</Options>
							<Options onClick={() => this.selected("Utilities")}>
								{" "}
								Utilities{" "}
							</Options>
							<Options onClick={() => this.selected("Dues and Subscriptions")}>
								{" "}
								Dues and Subscriptions{" "}
							</Options>
							<Options onClick={() => this.selected("Interest")}>
								{" "}
								Interest{" "}
							</Options>
							<Options onClick={() => this.selected("Rent")}> Rent </Options>
							<Options onClick={() => this.selected("Cell Phone")}>
								{" "}
								Cell Phone{" "}
							</Options>
							<Options onClick={() => this.selected("Land Phone")}>
								{" "}
								Land Phone{" "}
							</Options>
							<Options onClick={() => this.selected("Taxes-Local")}>
								{" "}
								Taxes-Local{" "}
							</Options>
							<Options onClick={() => this.selected("Taxes-Payroll")}>
								{" "}
								Taxes-Payroll{" "}
							</Options>
							<Options onClick={() => this.selected("Taxes-Sales")}>
								{" "}
								Taxes-Sales{" "}
							</Options>
						</DropDownColumn>
					) : (
						<DropDownColumn
							id="scroll"
							className="menu"
							height="auto"
							ref={(element) => {
								this.dropdownMenu = element;
							}}
						>
							<Options onClick={() => this.selected("Commission")}>
								Commission
							</Options>
							<Options onClick={() => this.selected("Sales")}>Sales</Options>
						</DropDownColumn>
					)
				) : null}
			</DropDown>
		);
	}
}

export default Dropdown;
