import styled from "styled-components";

export const EndpointDisplay = styled.div`
  width: 80%;
  height: auto;
  margin-top: 0px;
  margin-left: 250px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
`;
