import React from "react";
import MoneyEnvelopeImg from "../../assets/money_envelope.png";
import EllipseBlue from "../../assets/EllipseBlue.png";
import {
  Row_Centered,
  MoneyEnvelope,
  EllipseImg,
  RowFlexStart,
  PSize
} from "./_ModalStyles";

class Top3Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      top3: ["payroll", "insurance", "Credit Card Fees"]
    };
  }
  render() {
    return (
      <>
        <Row_Centered className="Top3Header">
          <MoneyEnvelope src={MoneyEnvelopeImg} alt="money in envelope" />
          <PSize color="#0078bd"> Top 3 Expenses</PSize>
        </Row_Centered>
        {this.state.top3.map(expense => (
          <RowFlexStart>
            <EllipseImg src={EllipseBlue} alt="ellipse" />
            <PSize color="#0078bd"> {expense}</PSize>
          </RowFlexStart>
        ))}
      </>
    );
  }
}

export default Top3Content;
