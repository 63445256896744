// Dev Note: This will be the modal for the real estate.
// Set up to receive data from the backend
// Style

// Library Imports
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ScrollAnimation from "react-animate-on-scroll";

// Assets $ Style Imports
import NavBar from "../navBar/NavBar.js";
import { EndpointContainer } from "../global/_EndpointContainer.js";
import { EndpointDisplay } from "../global/_EndpointDisplay.js";
import { TabWrapper } from "../global/_TabWrapper";

export default class RealEstate extends React.Component {
  render() {
    return (
      <EndpointContainer>
        <NavBar />
        <ScrollAnimation animateIn="fadeIn" animateOnce="true" duration="1.5">
          <EndpointDisplay>
            <h1>Real Estate</h1>
            <TabWrapper>
              <Tabs>
                <TabList>
                  <Tab>CURRENT BREAKEVEN</Tab>
                  <Tab>FIXED COSTS</Tab>
                  <Tab>VARIABLE</Tab>
                  <Tab>UNSORTED EXPENSES</Tab>
                </TabList>
                <TabPanel>
                  <h2>Any content 1</h2>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 2</h2>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 3</h2>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 4</h2>
                </TabPanel>
              </Tabs>
            </TabWrapper>
          </EndpointDisplay>
        </ScrollAnimation>
      </EndpointContainer>
    );
  }
}
