import React from "react";
import { connect } from "react-redux";
import { Breakeven_Info_Container } from "./_Breakeven_Styles";
import { Pie } from "react-chartjs-2";

const fakeData = {
  labels: ["Variable Costs", "Fixed Costs"],
  datasets: [
    {
      data: [45, 50],
      backgroundColor: ["#446A7D", "#B17373"],
      hoverBackgroundColor: ["#365565", "#8C5A5A"],
    },
  ],
};

class PieChart extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return (
      <Breakeven_Info_Container>
        <div>
          <Pie
            data={fakeData}
            width={400}
            height={500}
            options={{
              tooltips: {
                displayColors: false,
                mode: "label",
                callbacks: {
                  label: function (tooltipItem, data) {
                    var idx = tooltipItem["index"];
                    var caption = data.labels[idx];
                    var captionValue = data["datasets"][0]["data"][idx];
                    return caption + ": " + captionValue + "%";
                  },
                },
              },
            }}
          />
        </div>
      </Breakeven_Info_Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(PieChart);
