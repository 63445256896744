import React from "react";
import { Row_Centered } from "./_Breakeven_Styles";
import VariablePieChart from "./VariablePieChart";
import Variable from "./Variable";
import InfoModal from './InfoModal'
import { ColumnCenter } from "./_ModalStyles";

class FixedCosts extends React.Component {
	state = {
		color: ""
	};

	changeColor = (hoveredColor) => {
		this.setState({ color: hoveredColor });
		console.log(this.state.color);
	};
	render() {
		return (
            <ColumnCenter padding = "0 0 50px 0" margin="0 0 50px 0"> 
                <Row_Centered theme={{ alignItems: "center", justifyContent: "center" }}>
                    <VariablePieChart getColor={(e) => this.changeColor(e)} />
                    <InfoModal/>
                </Row_Centered>
                <Variable />
            </ColumnCenter>
		);
	}
}
export default FixedCosts;
