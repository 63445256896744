import React from 'react'
import {RowCenter} from '../global/_GlobalStyles'
import InputCard from './InputCard'


class EquipmentProjections extends React.Component {
    render(){
        return(
            <RowCenter>
                <InputCard />
             
            </RowCenter>
        )
    }
}

export default EquipmentProjections