import React from "react";
import { connect } from "react-redux";
import {
    Card,
	RowCenter,
	ColumnCenter,
    More_Button,
    Porter,
    SpaceAround,
    SpaceBetween,
    P
} from '../../global/_GlobalStyles'

class MonthlyDebtContent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// Keeping it DRY
		let {
			fixed_expenses,
			variable_expenses,
			breakeven,
			variable_costs,
			fixed_costs,
		} = this.props.state.breakeven_data.breakeven_data;

		let first_three_fixed_expenses = fixed_expenses.slice(0, 3);

		let first_three_variable_expenses = variable_expenses.slice(0, 3);

		return (
                <Card flexDirection="column" justifyContent="flex-start" margin="25px 0"> 
                    <ColumnCenter width="80%" padding= "36px 0">
                        <Porter fontSize="28px">Debt</Porter>
                    <SpaceBetween padding="15px 0 10px 0" borderBottom="1px solid gray">
                        <P width="100%" justifyContent="flex-start"> Revolving Credit </P>
                        <RowCenter> 
                            <P>Total</P>
                            <P padding="0 15px" color="#EB5757">${variable_costs}</P>
                        </RowCenter>
                    </SpaceBetween>
                        {/* Maps through the next two variable expenses */}
                        {first_three_variable_expenses.map((expenses) => (
                            <SpaceBetween padding="5px 0">
                                <P color="#6B6B6B">{expenses.transaction_name}</P>
                                <P>{expenses.amount}</P>
                            </SpaceBetween>
                        ))}
                    <More_Button onClick={() => this.props.handleButtonClick(2)} margin="5px 0 0 0">
                        {/* onClick sends the number that redirects user to the corresponding tab */}
                        MORE
                    </More_Button>
                    <SpaceBetween padding="15px 0 10px 0" borderBottom="1px solid gray">
                        <P width="100%" justifyContent="flex-start"> Installments </P>
                        <RowCenter padding="15px 0"> 
                            <P>Total</P>
                            <P padding="0 15px" color="#EB5757">${variable_costs}</P>
                        </RowCenter>
                    </SpaceBetween>
                        {/* Maps through the first three fixed expenses */}
                        {first_three_fixed_expenses.map((expenses) => (
                            <SpaceBetween padding="5px 0">
                                <P color="#6B6B6B">{expenses.transaction_name}</P>
                                <P>{expenses.amount}</P>
                            </SpaceBetween>
                        ))}
                    <More_Button onClick={() => this.props.handleButtonClick(3)} margin="5px 0 0 0">
                        {/* onClick sends the number that redirects user to the corresponding tab */}
                        MORE
                    </More_Button>
                    </ColumnCenter>
                </Card>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		state,
	};
};

export default connect(mapStateToProps)(MonthlyDebtContent);