import React from 'react'
import BankBuilding from "../../../assets/BankBuilding.png"
import CreditCard from "../../../assets/CreditCard.png"
import FlyingBill from "../../../assets/FlyingBill.png"
import Trophy from "../../../assets/Trophy.png"
import {
    Card,
	RowCenter,
	ColumnCenter,
    More_Button,
    Porter,
    Image,
    SpaceAround,
    SpaceBetween,
    P,
    RectangleBox
} from '../../global/_GlobalStyles'

class FinancialHealthInfo extends React.Component{

    render(){
        return(
            <ColumnCenter width="95%">
                <RowCenter padding="25px 0">
                    <Image src={BankBuilding} alt="Bank Building" margin="0 28px 0 0"/>
                    <Porter fontSize="35px">Your Financial health matters</Porter>
                </RowCenter>
                <RowCenter> 
                    <P color="#434343" fontSize="25px">as a business owner, your personal finances matter, how much you earn, and how it affects your business.</P>
                </RowCenter>
                <RectangleBox background="#EEEEEE">
                    <Image src={CreditCard} alt="Credit Card" margin="0 28px 0 0" width="7em"/>
                    <P justifyContent="flex-start" color="#434343" fontSize="25px">when you make a purchase and put it on “the business”, yet utilize your personal card, it’s extra money you need to account for when it comes time to pay that bill.</P>
                </RectangleBox>
                <RectangleBox background="#276789">
                    <P justifyContent="flex-start" color="white" fontSize="25px">we provided a suggested minimum payment for your revolving credit to help pay it off faster, which is included in your suggested salary.</P>
                     <Image src={FlyingBill} alt="Dollar with Wings" margin="0 28px 0 0" width="7em"/>
                </RectangleBox>
                <RowCenter padding="29px 0">
                    <P  color="#434343" fontSize="25px">our goal is to help you succeed financially and prepare for the future!</P>
                    <Image src={Trophy} alt="Trophy" margin="0 28px 0 0" width="7em"/>
                </RowCenter>
            </ColumnCenter>
        )
    }
}

export default FinancialHealthInfo;