import React from "react";
import LeftCard from "../RevolvingTab/LeftCard";
import InstallmentAccts from "./InstallmentAccts";
import { RowCenter } from '../../global/_GlobalStyles'
import RefinanceImg from '../../../assets/RefinanceImg.png'

class Installments extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<RowCenter alignItems="flex-start" >
				<LeftCard src={RefinanceImg} />
				<InstallmentAccts />
			</RowCenter>
		);
	}
}

export default Installments;
