import styled from "styled-components";

export const Estimates = styled.div`
  // border: 1px solid red;
  display: flex;
  width: 99%;
  height: 200px;
  margin-top: 220px;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;
