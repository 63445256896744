import { FETCH_DATA, LOGIN } from "../actions/types";


export const reducers = (state = {}, action) => {
    switch(action.type) {
        case FETCH_DATA:
            return action.payload;
        case LOGIN:
            return action.payload
        default:
            return state;
    }
}