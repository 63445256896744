import React from "react";

import { InfoModalContainer, OptionButton, Row_Centered, InfoModalDiv } from "./_ModalStyles";
import Top3Content from "./TopThreeContent";
import NewExpenses from "./NewExpenses";

class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
    };
  }

  async componentDidMount() {
    await this.setState({ selected: "top" });
  }

  toggleNewSection(feature) {
    this.setState({ selected: feature });
  }

  render() {
    let passColor = this.state.selected;
    let theChild = undefined;

    passColor === "top"
      ? (theChild = <Top3Content />)
      : (theChild = <NewExpenses />);

    return (
    <InfoModalDiv>
      <InfoModalContainer>
        <Row_Centered padding = "25px 0">
          <OptionButton
            onClick={() => this.toggleNewSection("top")}
            background={passColor === "top" ? "#0078BD" : "white"}
            color={passColor === "top" ? "white" : "black"}
          >
            {" "}
            Top 3{" "}
          </OptionButton>
          <OptionButton
            onClick={() => this.toggleNewSection("new")}
            background={passColor === "new" ? "#F07B7B" : "white"}
            color={passColor === "new" ? "white" : "black"}
          >
            {" "}
            New
          </OptionButton>
        </Row_Centered>
        <div>{theChild}</div>
      </InfoModalContainer>
       </InfoModalDiv>
    );
  }
}

export default InfoModal;